import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 1,
  "title": "App switching",
  "subtitle": "How to use Criipto Verify in app-switch mode"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Native apps`}</h2>
    <p>{`If you are building a native app, and would like to get the smoothest possible UX, you can use Criipto Verify's app-switching capabilities (for eIDs that support it).`}</p>
    <p>{`This will require that you take on a bit more work to orchestrate the login process in comparison with just going with the browser-based flow, but the net result is a much better UX.`}</p>
    <p>{`You should always start by detecting the presence of the native eID app on the users device. See below for `}<a parentName="p" {...{
        "href": "#app-detection"
      }}>{`app-detection examples`}</a>{`.`}</p>
    <p>{`If the desired app is present on the users device, you must augment the authorize request you send to Criipto Verify so it contains one of the following values (also OS dependent)`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`login_hint=appswitch:ios`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`login_hint=appswitch:android`}</inlineCode></li>
    </ul>
    <p>{`The value must be sent in the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` query parameter. Further details on this (and other) parameters in an authorize request can be found `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro#authorize-request-parameters"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Your app is responsible for sending the appropriate value for the platform it is deployed on.`}</p>
    <h3>{`App detection`}</h3>
    <Highlight icon="exclamation" mdxType="Highlight">
      <p>{`From June 6, 2023 (MitID Release 11) and onwards, app-detection for MitID is no longer relevant.
After that date, end users will be presented with "Open MitID app" buttons in the MitID Core Client, regardless of any effort by the SP's app to detect the presence of the MitID app.
We have therefore removed the previous guidance for MitID app detection from our documentation.`}</p>
    </Highlight>
    <p>{`The following code and configuration snippets illustrate how you can detect the presence of the Swedish BankID app.`}</p>
    <h4>{`Android`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public boolean deviceHasSEBankIdApp() {
  try {
    getPackageManager().getPackageInfo("com.bankid.bus", 0);
    return true;
  } catch (PackageManager.NameNotFoundException e) {
    return false;
  }
}
`}</code></pre>
    <Highlight icon="exclamation" mdxType="Highlight">
      <p>{`From Android 11, your applications `}<inlineCode parentName="p">{`Manifest.xml`}</inlineCode>{` must contain the following to be able to perform this query:`}</p>
    </Highlight>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<manifest ...>
  <queries>
    <package android:name="com.bankid.bus" />
  </queries>
  <application .... />
</manifest>
`}</code></pre>
    <h4>{`iOS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func canOpenSEBankIDApp() -> Bool {
  guard let url = URL(string: "https://app.bankid.com/.well-known/apple-app-site-association") else {
    return false
  }
  return UIApplication.shared.canOpenURL(url)
}
`}</code></pre>
    <h3>{`Swedish BankID`}</h3>
    <p>{`App switch is supported for the same-device login flow (`}<inlineCode parentName="p">{`acr_values=urn:grn:authn:se:bankid:same-device`}</inlineCode>{`).`}</p>
    <p>{`We recommend that you use `}<inlineCode parentName="p">{`response_mode=json`}</inlineCode>{` or `}<inlineCode parentName="p">{`response_mode=query`}</inlineCode>{` for this flow.`}</p>
    <p>{`For the `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` in the authorize request, you can experiment with using either a universal link for your app, or a custom file handler. Which one is more robust depends on the version of the OS you are on. Newer OS versions typically work best with universal links.`}</p>
    <p>{`The response to the authorize request will change to `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` with a JSON payload of format along the lines of:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "launchLinks": {
    "universalLink": "https://app.bankid.com/?autostarttoken=...&redirect=...",
    "customFileHandlerUrl": "bankid:///?autostarttoken=...&redirect=..."
  },
  "cancelUrl": "...",
  "completeUrl": "..."
}
`}</code></pre>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`The value of the redirect parameter depends on the supplied platform in the `}<inlineCode parentName="p">{`login_hint=appswitch:...`}</inlineCode>{` parameter.
If the app sends the wrong parameter, the flow cannot be expected to work correctly.`}</p>
    </Highlight>
    <p>{`Your app must use one of the `}<inlineCode parentName="p">{`launchLinks`}</inlineCode>{` values to open the native BankID app.
The `}<inlineCode parentName="p">{`universalLink`}</inlineCode>{` is recommended, but some older platforms may only support the `}<inlineCode parentName="p">{`customFileHandlerUrl`}</inlineCode>{` syntax.
We provide both so you can target the widest possible range of OS versions.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`cancelUrl`}</inlineCode>{` can be used to to stop an active authentication request.
This can be useful in case `}</p>
    <ul>
      <li parentName="ul">{`You are pre-filling the SSN`}</li>
      <li parentName="ul">{`The user does not complete the authentication in the BankID application`}</li>
      <li parentName="ul">{`The user navigates manually back to your app  `}</li>
    </ul>
    <p>{`When that happens, the BankID login for the specified SSN is blocked for a few minutes, unless you cancel the login.`}</p>
    <p>{`When control returns to your app, issue an HTTP GET to the `}<inlineCode parentName="p">{`completeUrl`}</inlineCode>{` (without any modifications).
Expect an OAuth2-formatted response.
Depending on the value of the `}<inlineCode parentName="p">{`response_mode`}</inlineCode>{` you use in your authorize request, the response will be either a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{`, a `}<inlineCode parentName="p">{`302 Redirect`}</inlineCode>{`, or a `}<inlineCode parentName="p">{`400 Bad Request`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`200 OK`}</inlineCode>{`: (when sending `}<inlineCode parentName="p">{`response_mode=json`}</inlineCode>{`) The response payload will be a JSON literal with a string-valued `}<inlineCode parentName="p">{`location`}</inlineCode>{` property. The value will be a URL where the relevant response parameters are in the query section. You should start by checking for errors (an `}<inlineCode parentName="p">{`error`}</inlineCode>{` query parameter will be present in the response). If not an error, pick out the `}<inlineCode parentName="p">{`code`}</inlineCode>{` and `}<inlineCode parentName="p">{`state`}</inlineCode>{` parameters and use your OIDC library to exhange the `}<inlineCode parentName="p">{`code`}</inlineCode>{` to an `}<inlineCode parentName="p">{`id_token`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`302 Redirect`}</inlineCode>{`: (when sending `}<inlineCode parentName="p">{`response_mode=query`}</inlineCode>{`) The Location response header value will be a URL where the relevant response parameters are in the query section. You should perform the same OAuth2-response processing as described for the `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` case.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`400 Bad Request`}</inlineCode>{`: Can have several causes, we recommend that you invoke the `}<inlineCode parentName="p">{`cancelUrl`}</inlineCode>{` and present the user with the option to login again.`}</p>
      </li>
    </ul>
    <h3>{`Danish MitID`}</h3>
    <p>{`You must (still) use a either a `}<inlineCode parentName="p">{`Custom Tab`}</inlineCode>{` (on `}<inlineCode parentName="p">{`Android`}</inlineCode>{`) or an `}<inlineCode parentName="p">{`ASWebAuthenticationSession`}</inlineCode>{` (on `}<inlineCode parentName="p">{`iOS`}</inlineCode>{`) to run the login flow in your app, but an app-switch button will show up in the MitID Core Client, allowing the user to launch the MitID app from the browser.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`On `}<inlineCode parentName="p">{`iOS`}</inlineCode>{`, you can also use `}<inlineCode parentName="p">{`SFAuthenticationSession`}</inlineCode>{` if the `}<inlineCode parentName="p">{`ASWebAuthenticationSession`}</inlineCode>{` is unavailable for the version you are targeting.
If you must target very old OS versions, `}<inlineCode parentName="p">{`SFSafariViewController`}</inlineCode>{` can be used as a fallback, but some features might not work properly.`}</p>
    </Highlight>
    <p>{`Once the flow completes, the MitID app will perform an app-switch back to your app, which makes the `}<inlineCode parentName="p">{`Custom Tab`}</inlineCode>{` / `}<inlineCode parentName="p">{`ASWebAuthenticationSession`}</inlineCode>{` resume its operation,
this initial resuming of your app will not include any `}<inlineCode parentName="p">{`OAuth2`}</inlineCode>{` parameters, however in resuming your app the MitID login request will continue in the web component,
completing the login process which will issue an `}<inlineCode parentName="p">{`OAuth2`}</inlineCode>{` formatted response.`}</p>
    <p>{`Assuming your `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` is also pointing to your native app, you will need to handle two sets of deep links,
one initial from MitID with no parameters, and a second one from Criipto with parameters.`}</p>
    <p>{`You can define a seperate URL for the MitID resume operation by using a `}<inlineCode parentName="p">{`appswitch:resumeUrl:{UNIVERSAL LINK}`}</inlineCode>{` login_hint.
MitID will only resume to a universal link, but if you define a separate `}<inlineCode parentName="p">{`resumeUrl`}</inlineCode>{` your `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` can be a custom scheme.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`resumeUrl`}</inlineCode>{` login_hint must be combined with your other appswitch login_hints, for example `}<inlineCode parentName="p">{`appswitch:android%20appswitch:resumeUrl:{UNIVERSAL LINK}`}</inlineCode></p>
    <h4>{`Android notes`}</h4>
    <p>{`It is not entirely intuitive how the switchback / resume flow can be handled on this OS.
The following setup has been observed to work:`}</p>
    <ol>
      <li parentName="ol">{`Set a dedicated activity for the eID login UI flow, and set the following properties in your applications `}<inlineCode parentName="li">{`Manifest.xml`}</inlineCode>{`.`}</li>
    </ol>
    <Highlight icon="exclamation" mdxType="Highlight">
      <p>{`Remember to replace the values of the `}<inlineCode parentName="p">{`android.host`}</inlineCode>{` and `}<inlineCode parentName="p">{`android.pathPattern`}</inlineCode>{` properties in the `}<inlineCode parentName="p">{`data`}</inlineCode>{` node.
These values must correspond to the `}<a parentName="p" {...{
          "href": "https://developer.android.com/training/app-links"
        }}>{`App Link for your app`}</a>{`.
You can read more about `}<a parentName="p" {...{
          "href": "https://developer.android.com/training/app-links/verify-site-associations"
        }}>{`how App Links work here`}</a>{`.`}</p>
    </Highlight>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<activity
        android:name=".activities.mitIDLoginActivity"
        android:launchMode="singleTop">
           <intent-filter android:autoVerify="true">
                <action android:name="android.intent.action.VIEW" />
                <category android:name="android.intent.category.DEFAULT" />
                <category android:name="android.intent.category.BROWSABLE" />
                <data
                        android:host="yourwebdomain.com"
                        android:pathPattern="/yourRedirectUrlPath"
                        android:scheme="https" />
            </intent-filter>
</activity>
`}</code></pre>
    <p>{`After the app links are configured properly, register the app link as a `}<em parentName="p">{`Callback URL`}</em>{` in Criipto, and set it as the `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{` query parameter in the `}<inlineCode parentName="p">{`authorize request`}</inlineCode>{` towards Criipto Verify.
You can read more about the anatomy of the `}<inlineCode parentName="p">{`authorize request`}</inlineCode>{` in our `}<a parentName="p" {...{
        "href": "/verify/guides/authorize-url-builder/#auth-methods--acr-values"
      }}>{`authorize URL builder`}</a>{`.`}</p>
    <p>{`Show the custom tab in the `}<inlineCode parentName="p">{`mitIDLoginActivity`}</inlineCode>{`, and the user will be taken to MitID app during the login process.
After Login, the context switches back to your app automatically because of the app link used in the `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{`.
When the custom tab tries to load your app link, you can access the returned `}<inlineCode parentName="p">{`code`}</inlineCode>{` value in an override of the `}<inlineCode parentName="p">{`onNewIntent`}</inlineCode>{` function of the `}<inlineCode parentName="p">{`mitIDLoginActivity`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`override fun onNewIntent(intent: Intent?) {
    super.onNewIntent(intent)
    val data = intent?.data
    if (data != null && data.isHierarchical) {
        if (data.getQueryParameter("error") != null) {
            val error = data.getQueryParameter("error")
            if (data.getQueryParameter("error_description") != null) {
              val error_description = data.getQueryParameter("error_description")
            }
            // Handle error in a way that matches your UX requirements
        }
        if (data.getQueryParameter("code") != null) {
            val code = data.getQueryParameter("code")
            // Exchange the \`code\` to a token using the standard OAuth2/OIDC protocols.
            // Exactly how to do this depends on your choice of flow.
            // If you are using the PKCE flow, you can run the exchange directly from your app.
            // If you are using the traditional authorization code flow, send the code to your server backend so it can run the exchange.
        }
    }
}
`}</code></pre>
    <h2>{`Web apps`}</h2>
    <h3>{`Danish MitID #web-apps-danish-mitid`}</h3>
    <p>{`The MitID login UX to web apps also benefits from app-switching, though only in the switch from the browser to the MitID app.
The user must still manually switch back to the browser once they have approved the login in their MitID app.`}</p>
    <Highlight icon="exclamation" mdxType="Highlight">
      <p>{`This has changed on June 6, 2023 (with MitID Release 11). Previously, automated switchback to the browser could be achieved on iOS, but that is no longer the case.`}</p>
    </Highlight>
    <h4>{`Working with the test-version(s) of the MitID app`}</h4>
    <p>{`The test version(s) can be found here: `}<a parentName="p" {...{
        "href": "https://pp.mitid.dk/mitid-app/index.html"
      }}>{`https://pp.mitid.dk/mitid-app/index.html`}</a>{`.
Note that on Android, the test version cannot co-exist with the production version, so you need a dedicated Android device for testing.
On iOS, the 2 app versions can co-exist, but doing so can lead to surprising behaviour at runtime. The app-switching is (sometimes) done to the "latest active" version of the app, so you might end up in your production app while testing.
We recommend that you always close the production version of the  MitID app before running any tests.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      